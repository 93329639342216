const initialState = {
  products: [],
  images: {}, // Using an object to map product IDs to their images
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PRODUCTS":
      return {
        ...state,
        products: action.payload,
      };

    case "SET_PRODUCT_IMAGES":
      return {
        ...state,
        images: {
          ...state.images,
          [action.payload.productId]: action.payload.images,
        },
      };

    default:
      return state;
  }
};

export default productReducer;
