import React, { useEffect, useState } from "react";
import ProductDisplayComponent from "../components/features/ProductDisplayComponent";
import SortingComponent from "../components/features/SortingComponent";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setProducts, setProductImages } from "../redux/actions/productActions";
import { MenuItem, Select } from "@mui/material";
import axios from "axios";

const Shop = () => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.products.products);
  const productImages = useSelector((state) => state.products.images);
  const [originalProducts, setOriginalProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          "https://admin.demiidesign.com/api/Product/"
        );
        const data = response.data;

        // Filter products with Product_Status === 'Active'
        const activeProducts = data.filter(
          (product) => product.Product_Status === "Active"
        );

        // Sort products by creation date in descending order (newest first)
        const sortedProducts = activeProducts.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        dispatch(setProducts(sortedProducts));
        setOriginalProducts(sortedProducts);

        const uniqueCategories = [
          ...new Set(sortedProducts.map((item) => item.Product_Style)),
        ];
        setCategories(uniqueCategories);

        // Fetch images for each product and store them in Redux
        sortedProducts.forEach(async (product) => {
          try {
            const imageResponse = await axios.get(
              `https://admin.demiidesign.com/api/Get/Image/Product/${product.Product_Name}`
            );
            dispatch(setProductImages(product.Product_Id, imageResponse.data));
          } catch (error) {
            console.error(
              `Error fetching images for ${product.Product_Name}:`,
              error
            );
            // Set a default image in case of an error
            dispatch(
              setProductImages(product.Product_Id, ["defaultImage.jpg"])
            );
          }
        });
      } catch (error) {
        console.error("Failed to fetch products:", error);
      }
    };

    fetchProducts();
  }, [dispatch]);


  const handleSort = (sortOrder) => {
    const sortedItems = [...items].sort((a, b) =>
      sortOrder === "asc"
        ? a.Product_Price - b.Product_Price
        : b.Product_Price - a.Product_Price
    );
    dispatch(setProducts(sortedItems));
  };

  const handleFilter = (category) => {
    if (category === "all") {
      dispatch(setProducts(originalProducts));
    } else {
      const filteredItems = originalProducts.filter(
        (item) => item.Product_Style === category
      );
      dispatch(setProducts(filteredItems));
    }
    setSelectedCategory(category);
  };

  // Combine products with their respective images from Redux
  const productsWithImages = items.map((product) => ({
    ...product,
    image: productImages[product.Product_Id] || ["defaultImage.jpg"],
  }));

  return (
    <div className="w-full flex flex-col items-center p-8 md:p-16 gap-4">
      <h1 className="text-slate-500 text-3xl font-bold">OUR SHOP</h1>
      <div className="w-full h-fit flex gap-5 flex-col md:flex-row justify-between items-center">
        <div className="flex gap-2 items-center text-slate-400">
          <Link to={"/"} className="hover:text-slate-800 duration-150">
            Home
          </Link>
          <span>/</span>
          <Link to={"/shop"} className="hover:text-slate-800 duration-150">
            Shop
          </Link>
        </div>
        <ul className="flex flex-col md:flex-row gap-4 font-light text-base mt-4 md:mt-0">
          <button
            onClick={() => handleFilter("all")}
            className={`md:mr-4 ${
              selectedCategory === "all" ? "selected" : ""
            }`}
          >
            ALL
          </button>
          {categories.slice(0, 4).map((category) => (
            <button
              key={category}
              onClick={() => handleFilter(category)}
              className={`md:mr-4 ${
                selectedCategory === category ? "selected" : ""
              }`}
            >
              {category.toUpperCase()}
            </button>
          ))}
          {categories.length > 4 && (
            <Select
              value={selectedCategory}
              onChange={(e) => handleFilter(e.target.value)}
              displayEmpty
              variant="standard"
              className="md:mr-4"
              MenuProps={{
                PaperProps: {
                  style: { maxHeight: 200, width: 250 },
                },
              }}
              renderValue={(selected) => {
                return categories.includes(selected) || selected === "all"
                  ? selected.toUpperCase()
                  : "More Styles";
              }}
              sx={{
                border: "#1B1C1D",
                "& .MuiSelect-select": { borderColor: "#1B1C1D" },
                "& .MuiSelect-select:focus": { borderColor: "#1B1C1D" },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "#1B1C1D" },
                  "&:hover fieldset": { borderColor: "#1B1C1D" },
                  "&.Mui-focused fieldset": { borderColor: "#1B1C1D" },
                },
              }}
            >
              <MenuItem value="all">ALL</MenuItem>
              {categories.slice(4).map((category) => (
                <MenuItem key={category} value={category}>
                  {category.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          )}
        </ul>
        <SortingComponent onSort={handleSort} />
      </div>
      <ProductDisplayComponent products={productsWithImages} />
    </div>
  );
};

export default Shop;
