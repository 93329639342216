import {LiaShoppingBagSolid,LiaTelegram} from 'react-icons/lia'
import {GiPriceTag,GiShoppingBag,GiHanger,GiMailbox,GiPaperPlane,GiPerson,GiPhone} from 'react-icons/gi'
import {CiInstagram,CiLinkedin,CiFacebook} from 'react-icons/ci'
import {PiTiktokLogoThin,PiArrowUp} from 'react-icons/pi'
import {RiAddFill, RiFileWarningLine} from 'react-icons/ri'
import {TfiFilter} from 'react-icons/tfi'
import { IoCheckmarkDone } from 'react-icons/io5'
import { RiFullscreenLine } from 'react-icons/ri'


export const ShopBag=LiaShoppingBagSolid;
export const Hanger=GiHanger;
export const ProductIcon=GiPriceTag;
export const MailBoxIcon=GiMailbox;
export const SendMailIcon=GiPaperPlane;
export const InstagramIcon=CiInstagram;
export const TelegramIcon=LiaTelegram;
export const LinkedinIcon=CiLinkedin;
export const FacebookIcon=CiFacebook;
export const TikTokIcon=PiTiktokLogoThin;
export const ViewAll=GiShoppingBag;
export const UserIcon=GiPerson;
export const PhoneIcon=GiPhone;
export const AddIcon=RiAddFill;
export const UpIcon=PiArrowUp;
export const FilterIcon=TfiFilter;
export const ErrorIcon=RiFileWarningLine;
export const SuccessIcon=IoCheckmarkDone;
export const FullScreen = RiFullscreenLine;