// redux/archiveActions.js

export const setArchives = (archives) => {
  return {
    type: "SET_ARCHIVES",
    payload: archives,
  };
};

export const setSelectedYear = (year) => {
  return {
    type: "SET_SELECTED_YEAR",
    payload: year,
  };
};
