import React, { useEffect, useState } from "react";
import OrderModal from "../components/modals/order_modal";
import DemiButton from "../components/features/DemiButton";
import { TextField, InputAdornment } from "@mui/material";
import {
  Phone as PhoneIcon,
  Person as UserIcon,
  LocationOn as LocationIcon,
} from "@mui/icons-material";
import axios from "axios";
import { DotLoading } from "../components/loading/Loading";
import { ErrorState, SuccesfulStates } from "../components/states/States";

const Order = ({ isModalOpen, handleModal, productData, order }) => {
  const [name, setName] = useState("");
  const [image, setImage] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [location, setLocation] = useState("Addis Ababa");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const imageResponse = await axios.get(
          `https://admin.demiidesign.com/api/Get/Image/Product/${productData.Product_Name}`
        );
        console.log("Fetched image data:", imageResponse.data); // Log to check structure
        // Assuming `imageResponse.data` is an array or contains a URL
        setImage(
          Array.isArray(imageResponse.data)
            ? imageResponse.data
            : [imageResponse.data]
        );
      } catch (error) {
        console.error(
          `Error fetching images for ${productData.Product_Name}:`,
          error
        );
      }
    };
    document.body.style.overflow = isModalOpen ? "hidden" : "auto";
    window.scroll(0, 0);
    fetchImages();
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen, productData.Product_Name]);


  const handleSuccess = () => {
    setIsSuccess(!isSuccess);
  };

  const handleError = () => {
    setIsError(!isError);
  };
  const fetchImageBlob = async (url) => {
    try {
      const response = await axios.get(url, { responseType: "blob" });
      return response.data;
    } catch (error) {
      console.error(`Error fetching image as blob from ${url}:`, error);
      throw error;
    }
  };

  // Function to send order data to the Telegram bot
 const sendToTelegram = async () => {
   const botToken = "6492328088:AAH0bNW4cRB3p7CQmwC0t2wweOS3YZ21yR8";
   const chatId = "366105512";
   const imageUrl = image[0]; // Assuming image[0] is the URL to the first image

   if (!imageUrl) {
     console.error("Image URL is missing for this order.");
     return;
   }

   try {
     const imageBlob = await fetchImageBlob(imageUrl);

     const formattedMessage = `
      | NEW ORDER HAS ARRIVED! |
      Product Code: ${productData.Product_Id}
      Product Ordered : ${productData.Product_Name}
      Customer Name : ${name}
      Customer's Phone Number : ${phoneNumber}
      Location : ${location}
      Total Amount of Items : ${order.length}
      ${Object.keys(order)
        .map((key) => {
          const { selectedSize, amount } = order[key];
          return `Size : ${selectedSize}, Amount : ${amount}`;
        })
        .join("\n")}
    `.slice(0, 1024); // Limit caption to 1024 characters

     const formData = new FormData();
     formData.append("chat_id", chatId);
     formData.append("photo", imageBlob, "product_image.jpg");
     formData.append("caption", formattedMessage);

     await axios.post(
       `https://api.telegram.org/bot${botToken}/sendPhoto`,
       formData,
       {
         headers: {
           "Content-Type": "multipart/form-data",
         },
       }
     );
     console.log("Message sent to Telegram successfully.");
   } catch (error) {
     console.error(
       "Error sending message to Telegram:",
       error.response?.data || error.message
     );
   }
 };

  // Function to send order data to the API
  const sendToAPI = async () => {
    const sizes = [];
    const amounts = [];

    for (const key of Object.keys(order)) {
      const { selectedSize, amount } = order[key];
      sizes.push(selectedSize);
      amounts.push(amount);
    }

    const sizesString = sizes.join(", ");
    const amountsString = amounts.join(", ");

    const orderData = {
      Product_Id: productData.Product_Id,
      Customer_Name: name,
      Customer_PhoneNumber: phoneNumber,
      Customer_Address: location,
      Order_Size: sizesString,
      Amount_Of_Order: amountsString,
      Total_Price: productData.Product_Price,
    };

    try {
      await axios.post("https://admin.demiidesign.com/api/Order", orderData);
      console.log("Order submitted to API successfully.");
    } catch (error) {
      console.error("Error submitting order to API:", error);
      throw error;
    }
  };

  const sendMessage = async () => {
    if (name === "") {
      setNameError(true);
    } else if (phoneNumber === "") {
      setNameError(false);
      setPhoneError(true);
    } else {
      setIsLoading(true);
      setNameError(false);
      setPhoneError(false);

      try {
        await Promise.all([sendToTelegram(), sendToAPI()]);
        handleSuccess();
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
        setName("");
        setPhoneNumber("");
        setLocation("Addis Ababa");
      }
    }
  };

  const handleName = (event) => {
    setName(event.target.value);
  };

  const handlePhone = (event) => {
    setPhoneNumber(event.target.value.replace(/\D/g, ""));
  };

  const handleLocation = (event) => {
    setLocation(event.target.value);
  };

  return (
    isModalOpen && (
      <OrderModal>
        <SuccesfulStates
          isSuccess={isSuccess}
          handleSuccess={handleSuccess}
          handleModal={handleModal}
        />
        <ErrorState
          Error={isError}
          handleError={handleError}
          handleModal={handleModal}
        />
        <div className="flex flex-col md:flex-row">
          <div className="md:w-64 w-full h-64 md:h-[28rem]">
            <img className="object-cover w-full h-full" src={image[0]} alt="" />
          </div>
          <div className="md:w-2/3 w-full p-10 flex flex-col items-center md:mx-5 space-y-5">
            <h1 className="text-base font-bold text-center md:text-left">
              Fill in the required information to order*
            </h1>
            <div className="w-full space-y-4">
              <TextField
                fullWidth
                label="Enter Full Name"
                variant="outlined"
                value={name}
                onChange={handleName}
                error={nameError}
                helperText={nameError && "Please provide your name!"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <UserIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                label="Enter Phone Number"
                variant="outlined"
                value={phoneNumber}
                onChange={handlePhone}
                error={phoneError}
                helperText={phoneError && "Please provide your phone number!"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                label="Enter Location"
                variant="outlined"
                value={location}
                onChange={handleLocation}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="w-full space-y-2">
              <DemiButton
                SubmitButton={!isLoading}
                outlined={isLoading}
                onClick={isLoading ? null : sendMessage}
                isLoading={isLoading}
                text={
                  isLoading ? (
                    <div className="p-2">
                      <DotLoading />
                    </div>
                  ) : (
                    "Submit Order"
                  )
                }
              />
              <DemiButton
                outlined={true}
                onClick={handleModal}
                text={"Cancel Order"}
              />
            </div>
          </div>
        </div>
      </OrderModal>
    )
  );
};

export default Order;
