const initialState = {
  selectedYear: null,
};

const yearReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_YEAR":
      return {
        ...state,
        selectedYear: action.payload,
      };
    default:
      return state;
  }
};

export default yearReducer;
