import React from 'react'
import BlackCircleLogo from '../assets/logo/Black Logo.png'
import { FacebookIcon, InstagramIcon, LinkedinIcon, PhoneIcon, TikTokIcon } from '../assets/Icons/DemiIcons'
import { Link } from 'react-router-dom'
const Footer = () => {
    return (
      <div className="w-full h-full text-slate-800 flex">
        <div className="w-full items-center justify-center h-fit mt-24 mb-10 flex flex-col gap-8">
          <div className="flex items-center justify-center h-full w-fit gap-4">
            <img className="w-14 h-14" src={BlackCircleLogo} alt="" />
            <h1 className="text-xl font-light">DEMII DESIGN</h1>
          </div>
          <div className="flex flex-col gap-3 items-center">
            <ul>
              <li className="text-xl font-normal w-full flex items-center h-full justify-center gap-2">
                <PhoneIcon />
                +251 92 011 2023
              </li>
            </ul>
            <ul className="font-demiiMedium flex gap-3 items-start">
              <li>
                <Link to="https://www.instagram.com/_____demii_____/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==">
                  <InstagramIcon
                    className="w-8 h-8 bg-slate-800 text-white rounded-full p-1
                             hover:bg-neutral-100 hover:text-slate-800 border cursor-pointer 
                             duration-100"
                  />
                </Link>
              </li>
              <li>
                <Link to="https://www.linkedin.com/company/demii/">
                  <LinkedinIcon
                    className="w-8 h-8  bg-slate-800 text-white rounded-full p-1
                            hover:bg-neutral-100 hover:text-slate-800 border cursor-pointer 
                            duration-100"
                  />
                </Link>
              </li>
              <li>
                <Link to="https://www.facebook.com/DesignedByDemii?mibextid=9R9pXO">
                  <FacebookIcon
                    className="w-8 h-8  bg-slate-800 text-white rounded-full p-1
                            hover:bg-neutral-100 hover:text-slate-800 border cursor-pointer 
                            duration-100"
                  />
                </Link>
              </li>
              <li>
                <Link to="https://www.tiktok.com/@___demii___?_t=8hx9Ivas8EI&_r=1">
                  <TikTokIcon
                    className="w-8 h-8  bg-slate-800 text-white rounded-full p-1
                            hover:bg-neutral-100 hover:text-slate-800 border cursor-pointer 
                            duration-100"
                  />
                </Link>
              </li>
            </ul>
          </div>
          <p className="font-light">
            © Demii design | All rights Reserved | 2023
          </p>
          <p className="font-light">
            {" "}
            <a
              href="https://www.tilettech.com/"
              target="_blank"
              rel="noreferrer"
            >
              Developed by TiletTech
            </a>
          </p>
        </div>
      </div>
    );
}

export default Footer