import React from 'react'
import HomeContainer from '../containers/HomeContainer'
import Collection from '../containers/Collection'
import Products from '../containers/Products'

const LandingPage = () => {
  return (
    <div className='h-full w-full'>
        <HomeContainer/>
        <Collection/>
        <Products/>
    </div>
  )
}

export default LandingPage