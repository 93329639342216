import React from 'react';
import { ShopBag } from '../../assets/Icons/DemiIcons';
import { Link } from 'react-router-dom';

const DemiCard = ({ name, price, image, size, id }) => {
  return (
    <Link to={`/clothDetail/${id}`}>
      <div
        onClick={() => window.scrollTo(0, 0)}
        className="w-80 md:w-84 h-[26rem] text-slate-800 overflow-hidden relative bg-neutral-50 border-slate-400 md:m-6 hover:cursor-pointer hover:text-black hover:border-demiblack transition-all"
      >
        <img
          className="object-cover w-full h-2/3 scale-100 hover:scale-105 hover:grayscale duration-200"
          src={image}
          alt=""
        />
        <ShopBag className="w-7 h-7 md:w-9 md:h-9 absolute right-3 top-3 hover:bg-demiblack hover:text-white duration-150 bg-white rounded-full shadow-md p-2" />
        <div className="mt-4 flex flex-col p-1 gap-2">
          <h3 className="text-lg md:text-xl font-semibold mb-1">{name}</h3>
          <div className="w-full justify-between items-center">
            <h1 className="font-semibold text-gray-800 text-lg md:text-xl">
              {price} <span className="font-medium text-sm">ETB</span>
            </h1>
            <h1 className="text-sm">Available Size: {size}</h1>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default DemiCard;
