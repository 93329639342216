import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import ClothDetail from "../pages/ClothDetail";
import Story from "../pages/Story";
import Archive from "../pages/Archive";
import Shop from "../pages/Shop";

const DemiiRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" Component={LandingPage} />
      <Route exact path="/clothDetail/:clothID" Component={ClothDetail} />
      <Route path="/story" Component={Story} />
      <Route path="/archive" Component={Archive} />
      <Route path="/shop" Component={Shop} />
    </Routes>
  );
};

export default DemiiRoutes;
