import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // Import Lightbox CSS
import { FullScreen } from "../assets/Icons/DemiIcons";
import { setArchives } from "../redux/actions/archiveActions";
import ArchiveNavigation from "../components/common/ArchiveNavigation";

// API endpoints
const ARCHIVE_API = "https://admin.demiidesign.com/api/Archive";
const IMAGE_API_BASE = "https://admin.demiidesign.com/api/Get/Image/Archive";

const ArchiveContainer = () => {
  const archives = useSelector((state) => state.archives.archives);
  const selectedYear = useSelector((state) => state.year.selectedYear);
  const dispatch = useDispatch();

  const [selectedImage, setSelectedImage] = useState(null);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [currentArchiveId, setCurrentArchiveId] = useState(null);
  const [images, setImages] = useState({}); // Images for each archive
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  //const [lightboxLoading, setLightboxLoading] = useState(true); // New loading state for Lightbox

  useEffect(() => {
    const fetchArchives = async () => {
      try {
        setLoading(true);

        // Fetch archive data
        const response = await axios.get(ARCHIVE_API);
        const fetchedArchives = response.data;
        dispatch(setArchives(fetchedArchives));

        // Fetch images for each archive
        const imagesData = {};
        await Promise.all(
          fetchedArchives.map(async (archive) => {
            try {
              const imageResponse = await axios.get(
                `${IMAGE_API_BASE}/${archive.Name}`
              );
              imagesData[archive.id] = imageResponse.data; // Store images
            } catch (error) {
              console.error(
                `Error fetching images for ${archive.Name}:`,
                error
              );
              imagesData[archive.id] = ["defaultImage.jpg"]; // Fallback image
            }
          })
        );
        setImages(imagesData);
        setLoading(false);
      } catch (err) {
        setError("Failed to load archives or images");
        setLoading(false);
      }
    };

    fetchArchives();
  }, [dispatch]);

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = selectedImage ? "hidden" : ""; // Manage scroll behavior
    return () => (body.style.overflow = "");
  }, [selectedImage]);

  const handleImageClick = (archiveId, image, index) => {
    setSelectedImage(image);
    setPhotoIndex(index);
    setCurrentArchiveId(archiveId); // Set the current archive ID when an image is clicked
    //setLightboxLoading(true); // Reset loading state when opening Lightbox
  };

  // Handle image load in the Lightbox
  const handleImageLoad = () => {
    //setLightboxLoading(false); // Image has loaded
  };

  if (loading) {
    return <p>Loading archives...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const filteredArchives = selectedYear
    ? archives.filter((archive) => archive.Year === selectedYear)
    : archives;

  return (
    <div className="flex flex-col items-center p-8">
      <div className="flex space-x-4 mb-8">
        <ArchiveNavigation />
      </div>
      <div className="flex flex-wrap justify-center">
        {filteredArchives.map((archive) => (
          <React.Fragment key={archive.id}>
            {images[archive.id] &&
              images[archive.id].map((image, index) => (
                <div
                  key={index}
                  className="m-2 max-w-xs relative group cursor-pointer"
                  onClick={() => handleImageClick(archive.id, image, index)}
                >
                  <img
                    src={image}
                    alt=""
                    loading="lazy"
                    className="transition object-cover h-full w-full duration-500 ease-in-out transform"
                  />
                  <div className="absolute bottom-0 inset-x-0 bg-black bg-opacity-50 flex items-center justify-between p-4 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                    <span className="text-white text-lg">{`Collection Year | ${archive.Year}`}</span>
                    <span className="text-white text-sm">
                      <FullScreen />
                    </span>
                  </div>
                </div>
              ))}
          </React.Fragment>
        ))}

        {selectedImage && currentArchiveId && (
          <Lightbox
            imageTitle={"Collection"}
            mainSrc={selectedImage}
            nextSrc={
              images[currentArchiveId][
                (photoIndex + 1) % images[currentArchiveId].length
              ]
            }
            prevSrc={
              images[currentArchiveId][
                (photoIndex + images[currentArchiveId].length - 1) %
                  images[currentArchiveId].length
              ]
            }
            onCloseRequest={() => setSelectedImage(null)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + images[currentArchiveId].length - 1) %
                  images[currentArchiveId].length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images[currentArchiveId].length)
            }
            onImageLoad={handleImageLoad} // Call when lightbox image is loaded
            imageLoadErrorMessage={"Failed to load image."}
            // Optionally show loading indicator
            //imageTitle={lightboxLoading ? "Loading..." : "Collection"}
          />
        )}
      </div>
    </div>
  );
};

export default ArchiveContainer;
