import React from "react";
import OrderModal from "../modals/order_modal";
import DemiButton from "../features/DemiButton";
import { ErrorIcon, SuccessIcon } from "../../assets/Icons/DemiIcons";

export const SuccesfulStates = ({ isSuccess, handleSuccess,handleModal }) => {
    
    const handleSuccessModal=()=>{
        handleSuccess();
        handleModal();
    }
  return isSuccess &&(
     (
      <OrderModal>
        <div className="w-full p-12 gap-2 h-fit flex-col flex text-center justify-center items-center">
          <SuccessIcon className="w-12 h-12 text-slate-800"/>
          <h1 className="font-bold">Success!</h1>
          <p>Your order is sent successfully, THANK YOU</p>
          <DemiButton SubmitButton={true} onClick={handleSuccessModal} text={'Close'}/>
        </div>
      </OrderModal>
    )
  );
};

export const ErrorState=({Error,handleModal,handleError})=>{
    return Error && (
        <OrderModal>
        <div className="w-full p-12 gap-2 h-fit flex-col flex justify-center items-center">
          <ErrorIcon className="w-12 h-12 text-neutral-400"/>
          <h1 className="font-bold">Oops!</h1>
          <p>Something went wrong</p>
          <DemiButton SubmitButton={true} onClick={handleError} text={'Try Again'}/>
        </div>
      </OrderModal>
    );
}
