import React, { useState, useEffect } from "react";
import Lion from "../assets/statics/Removed BG.png";
import { MdArrowRightAlt } from "react-icons/md";
import "./HomeContainer.css"; // Import a CSS file for animations
import { Link } from "react-router-dom";

const HomeContainer = () => {
  // Array of content (descriptions and button details)
  const contentArray = [
    {
      description:
        "Demii Design is dedicated to revolutionizing the tailoring experience by providing personalized, high-quality custom clothing services at the convenience of our customers. We strive to offer exceptional craftsmanship, exceptional customer service, and sustainable practices, empowering individuals to express their unique style with confidence.",
      buttonText: "Book Now",
      buttonTarget:"_blank",
      buttonLink: "https://forms.gle/cHXmzygcCJBMDRdFA",
    },
    {
      description:
        "Celebrating Ethiopian artistry, empowering local weavers, promoting handwoven fabrics, preserving cultural heritage, supporting communities sustainably, and showcasing Ethiopian fabric beautyglobally.",
      buttonText: "Shop Now",
      buttonTarget:"",
      buttonLink: "/shop",
    },
  ];

  // State to track the current index of content
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true); // For triggering fade effect

  // Cycle through content every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false); // Trigger fade out

      setTimeout(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === contentArray.length - 1 ? 0 : prevIndex + 1
        );
        setFade(true); // Trigger fade in
      }, 500); // Wait for the fade-out animation to finish (0.5s)
    }, 5000); // Change content every 5 seconds

    return () => clearInterval(interval);
  }, [currentIndex, contentArray.length]);

  return (
    <div className="bg-demiblack pb- w-full flex-col md:flex-row h-auto justify-between flex overflow-hidden text-slate-100 items-start font-light">
      <div className="w-full p-12 md:w-1/2 m-4 md:m-20 flex flex-col gap-4 justify-center">
        <h1 className="text-4xl md:text-5xl">
          <span className="font-semibold text-7xl">DEMII</span>
          <div className="w-12 md:w-20 h-[8px] my-2 md:my-5 bg-slate-100" />
          <h1 className="font-light text-7xl">DESIGN</h1>
        </h1>

        {/* Description and button section with fade effect */}
        <div className={`fade-transition ${fade ? "fade-in" : "fade-out"}`}>
          <p className="font-demiiLight text-base md:text-xl">
            {contentArray[currentIndex].description}
          </p>
          <Link
            to={contentArray[currentIndex].buttonLink}
            target={contentArray[currentIndex].buttonTarget}
            rel="noopener noreferrer"
            className="bg-white hover:bg-slate-800 hover:text-white duration-150 p-3 mt-2 text-demiblack text-xl text-start font-bold w-1/3 flex justify-between items-center"
          >
            {contentArray[currentIndex].buttonText}{" "}
            <MdArrowRightAlt className="w-8 h-8" />
          </Link>
        </div>
      </div>
      <div className="w-full md:w-1/3 md:h-full h-full flex items-end justify-end">
        <img
          className="grayscale object-cover w-full h-full"
          src={Lion}
          alt="Lion"
        />
      </div>
    </div>
  );
};

export default HomeContainer;
