import React, { useState, useEffect } from 'react';
import { UpIcon } from '../../assets/Icons/DemiIcons';

const BackToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    // Set the scroll threshold here (e.g., 300px from the top)
    const scrollThreshold = 800;
    setShowButton(window.scrollY > scrollThreshold);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <button
      className={`fixed bottom-6 z-50 right-6 bg-slate-800 text-white p-3 ${
        showButton ? 'visible' : 'invisible'
      }`}
      onClick={scrollToTop}
    >
      <UpIcon/>
    </button>
  );
};

export default BackToTopButton;
