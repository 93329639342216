// SortingComponent.js
import React from 'react';

const SortingComponent = ({ onSort }) => {
  return (
    <div className="flex items-center justify-center  text-slate-800 text-sm">
      <label className="mr-2">Price</label>
      <select
        className="px-4 py-2 bg-neutral-100 border rounded-md outline-none  focus:border-slate-800"
        onChange={(e) => onSort(e.target.value)}
      >
        <option className='m-3 text-sm' value="asc">Low to High</option>
        <option value="desc">High to Low</option>
      </select>
    </div>
  );
};

export default SortingComponent;
