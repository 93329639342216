import React from "react";
import DemiCard from "./DemiCard";

const ProductDisplayComponent = ({ products }) => {
  return (
    <div className="flex w-full items-center justify-center flex-wrap gap-4">
      {products.map((product) => (
        <DemiCard
          key={product.Product_Id}
          name={product.Product_Name}
          image={product.image[0] || "defaultImage.jpg"}
          price={product.Product_Price}
          size={product.Product_Size}
          id={product.Product_Id}
          className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4"
        />
      ))}
    </div>
  );
};

export default ProductDisplayComponent;
