
const initialState = {
  isLogged: true,
  accessToken: localStorage.getItem("accessToken") || null,
  token: "",
  username: "admin",
  password: "",
  error: "",
  success: "",
  isLoading: false,
  showPassword: false,
  loggeduser:"",
  userType:""
};

export const loginReducer = (state=initialState, action) => {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
      return {
        ...state,
        isLogged: true,
        accessToken: action.payload,
      };
    case 'LOGOUT':
      localStorage.removeItem('accessToken');
      return {
        ...state,
        isLogged: false,
        accessToken: null,
      };
        case "SET_USERNAME":
            return {
                ...state,
                username:action.payload
            }
        case "SET_PASSWORD":
            return {
                ...state,
                password:action.payload
            }
        case "SET_TOKEN":
            return {
                ...state,
                token:action.payload
            }
        case "SHOW_PASSWORD":
            return {
                ...state,
                showPassword:action.payload
            }
        case "IS_LOADING":
            return {
                ...state,
                isLoading: action.payload
            }
        case "IS_LOGGED":
            return {
                ...state,
                isLogged: action.payload
            }
        case "SET_LOGGEDUSER":
            return {
                ...state,
                loggeduser: action.payload
            }
        case "SET_USERTYPE":
            return {
                ...state,
                userType: action.payload
            }
        default:
            return initialState;
    }
}
