import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ImageSlider from "../components/features/ImageSlider";
import OrderContainer from "../containers/OrderContainer";
import { useDispatch, useSelector } from "react-redux";
import { setProducts } from "../redux/actions/productActions";
import axios from "axios";
//import YouMayAlsoLike from "../components/features/YouMayAlsoLike";

const ClothDetail = () => {
  const { clothID } = useParams();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.products);
  const [productImage, setProductImage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Find the selected product
  const selectedProduct = products.find((e) => e.Product_Id === clothID);

  useEffect(() => {
    const fetchProductsAndImages = async () => {
      try {
        // Fetch products only once
        const productsResponse = await axios.get(
          "https://admin.demiidesign.com/api/Product"
        );
        dispatch(setProducts(productsResponse.data));

        // Check if the selectedProduct is already available
        if (selectedProduct) {
          // Fetch images for the selected product
          const imagesResponse = await axios.get(
            `https://admin.demiidesign.com/api/Get/Image/Product/${selectedProduct.Product_Name}`
          );
          setProductImage(
            Array.isArray(imagesResponse.data)
              ? imagesResponse.data
              : ["defaultImage.jpg"]
          );
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setProductImage(["defaultImage.jpg"]); // Fallback image
        setIsLoading(false);
      }
    };

    fetchProductsAndImages();
  }, [dispatch, selectedProduct]);

  if (isLoading) {
    return <div>Loading...</div>; // Handle the loading state
  }

  if (!selectedProduct) {
    return <div>Product not found</div>; // Handle the case when the product is not found
  }

  return (
    <div className="font-demiiRegular h-full">
      <div className="flex flex-col items-start mt-0 md:mt-7 px-0 md:px-5 md:flex-row">
        <div className="w-full md:w-1/2">
          <ImageSlider selectedProduct={selectedProduct} images={productImage} />
        </div>
        <OrderContainer product={selectedProduct} />
      </div>
      {/* <YouMayAlsoLike
        selectedProduct={selectedProduct}
        suggestedProducts={products}
      /> */}
    </div>
  );
};

export default ClothDetail;
