import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setYear } from "../../redux/actions/yearAction";
import axios from "axios";
import { setArchives } from "../../redux/actions/archiveActions";

const ArchiveNavigation = () => {
  const dispatch = useDispatch();
  const archives = useSelector((state) => state.archives.archives); // Get archives from Redux store
  const selectedYear = useSelector((state) => state.year.selectedYear); // Get selected year from Redux store
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch archives data to ensure years are based on API response
  useEffect(() => {
    const fetchArchives = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://admin.demiidesign.com/api/Archive"
        );
        dispatch(setArchives(response.data));
        setLoading(false);
      } catch (err) {
        setError("Failed to load archives");
        setLoading(false);
      }
    };

    fetchArchives(); // Fetch archives on component mount
  }, [dispatch]);

  const handleYearClick = (year) => {
    dispatch(setYear(year));
  };

  // Get unique years from archives
  const years = Array.from(new Set(archives.map((archive) => archive.Year)));

  if (loading) {
    return <p>Loading years...</p>; // Display loading message while fetching
  }

  if (error) {
    return <p>{error}</p>; // Display error message if fetching fails
  }

  return (
    <div className="text-center w-screen space-x-2">
      <button
        onClick={() => handleYearClick(null)}
        className={`px-4 py-2 h-10 rounded hover:bg-white hover:text-demiblack ${
          selectedYear === null
            ? "bg-white text-demiblack shadow-md"
            : "bg-demiblack text-white"
        }`}
      >
        All
      </button>
      {years.map((year) => (
        <button
          key={year}
          onClick={() => handleYearClick(year)}
          className={`px-4 py-2 h-10 rounded hover:bg-white hover:text-demiblack ${
            year === selectedYear
              ? "bg-white text-demiblack shadow-md"
              : "bg-demiblack text-white"
          }`}
        >
          {year}
        </button>
      ))}
    </div>
  );
};

export default ArchiveNavigation;
