import { Turn } from "hamburger-react";
import React, { useState } from "react";
import { ShopBag } from "../../assets/Icons/DemiIcons";
import demiiLogo from "../../assets/logo/Logo_Circle.png";
import { Link } from "react-router-dom";

const MobileNavigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="md:hidden text-slate-100 bg-demiblack flex w-full px-6 h-full items-center py-5 justify-between">
      <div className="md:hidden block z-50">
        <Turn color="#fff" size={25} onToggle={handleOpen} toggled={isOpen} />
        {isOpen && (
          <div className="absolute bg-demiblack left-0 gap-10 text-center right-0 top-[10%] duration-200 py-5 flex items-center flex-col w-full">
            <Link to={"/"} onClick={handleOpen}>
              <img className="w-16" src={demiiLogo} alt="" />
            </Link>
            <ul className="font-light text-2xl flex flex-col gap-7">
              <Link to={"/"} onClick={handleOpen}>
                <p>Home</p>
              </Link>
              <Link to={"/story"} onClick={handleOpen}>
                <p>Story</p>
              </Link>
              <Link to={"/archive"} onClick={handleOpen}>
                <p>Archive</p>
              </Link>
            </ul>
          </div>
        )}
      </div>
      <Link
        to={"/shop"}
        onClick={() => setIsOpen(false)}
        className="flex items-center font-light justify-center h-full gap-3"
      >
        <ShopBag className="w-5 h-5" />
        Shop now
      </Link>
    </div>
  );
};

export default MobileNavigation;
