import React from 'react'
import PCNavigation from './PCNavigation'
import MobileNavigation from './MobileNavigation'

const NavigationBars = ({sticky}) => {
  return (
    <>
     <PCNavigation sticky={sticky}/>
     <MobileNavigation/>
    </>
  )
}

export default NavigationBars