export const ADD_ORDER = "ADD_ORDER";
export const REMOVE_ORDER = "REMOVE_ORDER";
export const UPDATE_ORDER_SIZE = "UPDATE_ORDER_SIZE";
export const UPDATE_ORDER_AMOUNT = "UPDATE_ORDER_AMOUNT";

export const addOrder = () => ({
  type: ADD_ORDER,
  payload: {
    amount: 1,
    selectedSize: "",
  },
});

export const removeOrder = (index) => ({
  type: REMOVE_ORDER,
  payload: index,
});

export const updateOrderSize = (index, size) => ({
  type: UPDATE_ORDER_SIZE,
  payload: { index, size },
});

export const updateOrderAmount = (index, amount) => ({
  type: UPDATE_ORDER_AMOUNT,
  payload: { index, amount },
});
