import React from 'react'

const DemiButton = ({ outlined, text, icon, onClick,SubmitButton }) => {
  return (
    <button onClick={onClick} className=
    {
    `border 
    ${outlined && 'text-slate-800 w-full bg-transparent hover:text-neutral-50 hover:bg-slate-800 border-slate-800 border'} 
    p-3 text-sm flex items-center justify-center gap-2 w-fi hover:text-black duration-100
    ${SubmitButton && 'bg-slate-800 text-neutral-50 w-full text-base hover:bg-neutral-200'}
    `}>
      {text}
      {icon}
    </button>
  )
}

export default DemiButton