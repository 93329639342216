import { combineReducers } from "@reduxjs/toolkit";
import { loginReducer } from "./reducers/loginReducer";
import orderReducer from "./reducers/OrderReducer";
import productReducer from "./reducers/ProductReducer";
import imageReducer from "./reducers/ImageReducer";
import archiveReducer from "./reducers/archiveReducer";
import yearReducer from "./reducers/yearReducer";

export const rootReducer = combineReducers({
  login: loginReducer,
  year: yearReducer,
  orders: orderReducer,
  products: productReducer,
  images: imageReducer,
  archives: archiveReducer,
});