import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddIcon, SendMailIcon } from "../assets/Icons/DemiIcons";
import DemiButton from "../components/features/DemiButton";
import Order from "../containers/Order";
import {
  addOrder,
  updateOrderSize,
  removeOrder,
  updateOrderAmount,
} from "../redux/actions/orderAction";

const OrderContainer = ({ product }) => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectionError, setError] = useState("");

  const handleModalOpen = () => {
    let allSizesSelected = true;
    for (let i = 0; i < orders.length; i++) {
      if (orders[i].selectedSize === "") {
        allSizesSelected = false;
        break;
      }
    }
    if (allSizesSelected) {
      setError("");
      setModalOpen(!isModalOpen);
    } else {
      setError("* Please select a size for your orders before proceeding. *");
    }
  };

  const handleSizeSelection = (orderIndex, selectedSize) => {
    dispatch(updateOrderSize(orderIndex, selectedSize));
  };

  const addNewOrder = () => {
    dispatch(addOrder());
  };

  const removeOrderFromList = (index) => {
    dispatch(removeOrder(index));
  };

  const incrementOrder = (index) => {
    dispatch(updateOrderAmount(index, orders[index].amount + 1));
  };

  const decrementOrder = (index) => {
    if (orders[index].amount > 1) {
      dispatch(updateOrderAmount(index, orders[index].amount - 1));
    }
  };

  const totalAmount = orders.reduce((total, order) => total + order.amount, 0);

  const productSizes = product.Product_Size
    ? product.Product_Size.split(",")
    : [];

  return (
    <>
      <Order
        order={orders}
        isModalOpen={isModalOpen}
        handleModal={handleModalOpen}
        productData={product}
      />
      <div className="w-full px-4 flex-1 flex h-fit flex-col gap-8 text-slate-800 items-start md:w-1/2">
        <div className="space-y-2">
          <h1 className="text-5xl font-black tracking-tighter">
            {product.Product_Name}
          </h1>
          <h1 className="font-semibold text-gray-800 text-3xl">
            {product.Product_Price}{" "}
            <span className="font-medium text-base">ETB</span>
          </h1>
          <h1>Category | {product.Product_Style}</h1>
          <h1>Composition | {product.Product_Composition}</h1>
          <p className="text-lg font-light">{product.Product_Description}</p>
        </div>
        <div className="w-3/4 h-[0.5px] bg-neutral-300" />
        <ul className="text-neutral-500 list-disc">
          <h1 className="font-bold text-slate-800 text-lg">About the Model</h1>
          {product.model?.Model_Hight && (
            <li>{product.model.Model_Hight.toUpperCase()}</li>
          )}
          {product.model?.Model_Size && (
            <li>{product.model.Model_Size.toUpperCase()}</li>
          )}
        </ul>
        <div className="w-3/4 h-[0.5px] bg-neutral-300" />
        <div className="flex w-3/4 justify-between items-center gap-3"></div>
        <div className="flex w-3/4 justify-between items-center gap-3">
          <h1 className="font-bold text-lg">Order here</h1>
          <h1 className="text-base font-demiiMedium">
            Total Orders | <span className=" text-base">{totalAmount}</span>
          </h1>
        </div>
        <span className="text-red-600 capitalize">{selectionError}</span>
        <div className="h-[132px] overflow-y-scroll w-full space-y-10 scrollbar-thin">
          {orders.map((o, index) => (
            <div key={index}>
              <div className="flex gap-5">
                {productSizes.map((size, sizeIndex) => (
                  <div key={sizeIndex}>
                    <div
                      onClick={() => handleSizeSelection(index, size)}
                      className={`${
                        o.selectedSize === size
                          ? "bg-slate-800 text-white"
                          : "bg-neutral-200"
                      } w-10 flex text-sm p-2 mb-8 items-center justify-center text-slate-800 border  hover:bg-slate-800 hover:text-white cursor-pointer`}
                    >
                      {size}
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex w-full items-center gap-3 text-3xl">
                <button
                  onClick={() => decrementOrder(index)}
                  className="bg-neutral-200 hover:bg-slate-800 hover:text-neutral-50 duration-150 text-xl text-slate-800 p-3 h-7 w-7 flex items-center justify-center rounded-full"
                >
                  -
                </button>
                <p className="font-serif text-base">{o.amount}</p>
                <button
                  onClick={() => incrementOrder(index)}
                  className="bg-neutral-200 hover:bg-slate-800 hover:text-neutral-50 duration-150 text-xl text-slate-800 p-3 h-5 w-5 flex items-center justify-center rounded-full"
                >
                  +
                </button>
              </div>
              {orders.length > 1 && (
                <button
                  onClick={() => removeOrderFromList(index)}
                  className="border border-slate-800 p-1 px-4 m-1 mt-5 text-sm hover:bg-slate-800 hover:text-neutral-50 transition-all"
                >
                  Delete
                </button>
              )}
            </div>
          ))}
        </div>
        <DemiButton
          onClick={addNewOrder}
          outlined={true}
          text={"Add another order"}
          icon={<AddIcon />}
        />
        <DemiButton
          onClick={handleModalOpen}
          SubmitButton={true}
          outlined={false}
          text={"Send Your Order"}
          icon={<SendMailIcon className="text-white" />}
        />
      </div>
    </>
  );
};

export default OrderContainer;
