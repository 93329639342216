const Textstory = [
  "Demii realized Ethiopia had riches and distinctive patterns that can be shared with the rest of the globe. It began" +
    "collaborating with local weavers to develop new patterns and techniques for thickening and strengthening the fabrics" +
    "so they could be transformed into items of ready-to-wear clothing. Ethiopian weavers first created traditional fabrics" +
    "using cotton fibers provided by small owner farmers and spun into yarns. This sub-sector of the traditional cottage" +
    "textile industry in Ethiopia has a long history. Traditional fabric production on handlooms was a passed-down custom" +
    "that was done as a means of subsistence and money generation. Such fabric was transformed via exquisite artisan" +
    "labor into the stunning traditional clothing of Ethiopia, combining the incredibly inventive weaving and embroidery techniques. ",

  "Demii sells exquisitely crafted clothing that is ethically and sustainably sourced. Each piece is woven from natural" +
    "materials with traditional weaving methods by creative weavers and artisans who are committed to upholding the" +
    "highest standards in their work. Demii creates intricate patterns and stunning motifs in close collaboration with" +
    "Ethiopian weavers. Demii also uses a variety of materials produced in various African nations, such as African Print, a" +
    "wax and cotton print with patterned motifs. It makes use of textiles such gabi, gojam azene, menen, various shema" +
    "works, and woven tiles and patterns from various textiles used by various Ethiopian ethnic groups",

  "Demii Design was incorporated in 2020 by Mekdes Mesfin. She named the startup company after her mom's name -" +
    "Deme. Mekdes was born and raised in Addis Ababa, Ethiopia. She studied Civil Engineering and Business" +
    "Management with a Bachelor's Degree. Though she had the opportunity to work as a site engineer and continue" +
    "working as an office manager at the technology service company, she chose to start working full-time on her dream" +
    "since high school. Designing and creating different art through her outfit had always been her passion. It comes so" +
    "naturally to her. So she went to a fashion design college to learn the ropes of fashion. But the college was closed due" +
    "to the Covid - 19, so she had to do it herself during quarantine. ",

  "Our goal is to transmit Ethiopian culture and traditional history by fusing them with contemporary, environmentally" +
    "friendly, and high-quality materials that feature exquisite designs and patterns.",

  "To serve clients with the best-value goods and services while innovating, leading, enhancing, and improving. to use" +
    "branding to make a difference and keep up with changing market conditions, fashion trends, and technological advancements",
];

const Story = ({ index, position, responsive }) => {
  return (
    <p
      className={`text-${position} md:text-${responsive} md:text-lg text-slate-600 font-light text-md`}
    >
      {Textstory[index]}
    </p>
  );
};
export default Story;
