import React from "react";
import { ShopBag } from "../../assets/Icons/DemiIcons";
import demiiLogo from "../../assets/logo/Logo_Circle.png";
import { Link } from "react-router-dom";

const PCNavigation = () => {
  return (
    <div className="md:flex hidden bg-demiblack text-lg text-white w-full justify-between p-4 px-12 items-center">
      <div>
        <Link to={"/"}>
          <img className="w-16" src={demiiLogo} alt="" />
        </Link>
      </div>
      <ul className="font-light text-lg flex gap-10">
        <li>
          <Link to={"/"} className="nav-link">
            Home
          </Link>
        </li>
        <li>
          <Link to={"/story"} className="nav-link">
            Story
          </Link>
        </li>
        <li>
          <Link to={"/archive"} className="nav-link">
            Archive
          </Link>
        </li>
      </ul>
      <Link
        className="flex items-center font-light justify-center gap-3 nav-link"
        to={"/shop"}
      >
        <ShopBag className="w-6 h-6" />
        Shop now
      </Link>
    </div>
  );
};

export default PCNavigation;
