import React from "react";
import "../../ImageSliderLoading.css"; // Import the CSS file

const ImageSliderLoading = () => {
  return (
    <div className="image-slider-loading">
      <div className="main-image-loading"></div>
      <div className="thumbnail-container-loading">
        <div className="thumbnail-loading"></div>
        <div className="thumbnail-loading"></div>
        <div className="thumbnail-loading"></div>
        <div className="thumbnail-loading"></div>
      </div>
    </div>
  );
};

export default ImageSliderLoading;
