import NavigationBars from './components/common/NavigationBars';
import BackToTopButton from './components/features/BackToTopButton';
import Footer from './containers/Footer';
import DemiiRoutes from './routes/DemiiRoutes';

function App() {

  return (
    <>
      <NavigationBars/>
      <DemiiRoutes/>
      <BackToTopButton />
      <Footer/>
    </>
  );
}

export default App;
