import React, { useEffect, useState } from "react";
import DemiCard from "../components/features/DemiCard";
import { ViewAll } from "../assets/Icons/DemiIcons";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Scrollbar, Mousewheel } from "swiper/modules";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import "swiper/css";
import "swiper/css/scrollbar";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setProductImages } from "../redux/actions/productActions";
import axios from "axios";
import ImageLoading from "../components/loading/imageLoading";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState({}); // Track loading state per product
  const dispatch = useDispatch();
  const productImages = useSelector((state) => state.products.images);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          "https://admin.demiidesign.com/api/Product/"
        );
        const activeAndRecentProducts = response.data.filter(
          (product) =>
            product.Product_Status === "Active" &&
            product.Recently === "Recently"
        );

        activeAndRecentProducts.forEach(async (product) => {
          setLoading((prev) => ({ ...prev, [product.Product_Id]: true }));
          try {
            const imageResponse = await axios.get(
              `https://admin.demiidesign.com/api/Get/Image/Product/${product.Product_Name}`
            );
            dispatch(setProductImages(product.Product_Id, imageResponse.data));
          } catch (error) {
            console.error(
              `Error fetching images for ${product.Product_Name}:`,
              error
            );
            dispatch(
              setProductImages(product.Product_Id, ["defaultImage.jpg"])
            );
          } finally {
            setLoading((prev) => ({ ...prev, [product.Product_Id]: false }));
          }
        });

        setProducts(activeAndRecentProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, [dispatch]);

  const swiperBreakpoints = {
    320: {
      slidesPerView: 1.1,
      spaceBetween: 8,
      centeredSlides: true,
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 30,
      freeMode: true,
    },
    768: {
      slidesPerView: 2.5,
      spaceBetween: 50,
      freeMode: true,
    },
    1024: {
      slidesPerView: 3.5,
      spaceBetween: 2,
      freeMode: true,
    },
  };

  const productsWithImages = products.map((product) => ({
    ...product,
    image: productImages[product.Product_Id] || ["defaultImage.jpg"],
  }));

  return (
    <div className="font-demiiSemiBold relative">
      <div className="md:ml-10 m-4 md:m-8 text-xl md:text-4xl flex items-center justify-between">
        <div>
          <h1 className="text-slate-800 font-light">Featured products</h1>
        </div>
        <div className="text-base md:text-xl font-demiiBold flex items-center gap-2 cursor-pointer">
          <ViewAll className="text-slate-600 w-4 md:w-6 h-6" />
          <Link
            to={"/shop"}
            className="text-slate-800 hover:text-slate-950 hover:scale-105 duration-150"
          >
            Show all products
          </Link>
        </div>
      </div>
      <div className="relative">
        <Swiper
          direction={"horizontal"}
          mousewheel={true}
          modules={[FreeMode, Scrollbar, Mousewheel]}
          scrollbar={{
            draggable: true,
            hide: true,
          }}
          breakpoints={swiperBreakpoints}
          className="mySwiper2 w-full h-full"
        >
          {productsWithImages.map((product) => (
            <SwiperSlide className="w-full" key={product.Product_Id}>
              {loading[product.Product_Id] ? (
                <ImageLoading />
              ) : (
                <DemiCard
                  name={product.Product_Name}
                  image={product.image[0]}
                  price={product.Product_Price}
                  size={product.Product_Size}
                  id={product.Product_Id}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Products;
