export const setProducts = (products) => {
  return {
    type: "SET_PRODUCTS",
    payload: products,
  };
};

export const setProductImages = (productId, images) => ({
  type: "SET_PRODUCT_IMAGES",
  payload: { productId, images },
});
