import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Collection = () => {
  const [archives, setArchives] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scroll(0, 0);

    const fetchArchives = async () => {
      try {
        const response = await fetch(
          "https://admin.demiidesign.com/api/Archive"
        );
        const data = await response.json();

        // Fetch associated images for each archive item
        const archivesWithImages = await Promise.all(
          data.slice(0, 3).map(async (archive) => {
            // Limit to first three archives
            const imageResponse = await fetch(
              `https://admin.demiidesign.com/api/Get/Image/Archive/${archive.Name}`
            );
            const images = await imageResponse.json();
            return { ...archive, cover: images[0] }; // Use the first image as cover
          })
        );

        setArchives(archivesWithImages);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch archive data:", error);
        setLoading(false);
      }
    };

    fetchArchives();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="font-demiiSemiBold flex w-full md:px-28 p-10 md:flex-row flex-col justify-center h-screen items-center md:my-0 my-20">
      <div className="md:-rotate-90 flex flex-col items-center w-36 justify-center">
        <h1 className="md:text-6xl text-4xl text-slate-800 font-light md:my-0 my-12">
          COLLECTION
        </h1>
        <p className="w-full font-light"></p>
      </div>
      <div className="grid md:grid-cols-2 md:grid-rows-2 grid-cols-2 grid-rows-3 gap-4 md:gap-9 w-full h-full m-4">
        {archives.map((archive, index) => (
          <Link
            key={archive.id}
            to={`/archive`}
            className={`grid-item ${
              index === 0
                ? "md:row-span-2 md:col-span-1 col-span-2"
                : "md:w-80 w-full"
            } bg-slate-100`}
          >
            <img
              className="object-cover w-full h-full"
              src={archive.cover}
              alt={archive.Name}
            />
            <div className="overlay-text w-full flex text-center md:text-sm text-sm flex-col items-center">
              <h1>
                OUR {archive.Name.toUpperCase()} COLLECTION FROM {archive.Year}
              </h1>
              <h1 className="text-sm">Go to Archive for more</h1>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Collection;
