import React from "react";

export const DotLoading = () => {
  return (
    <div class="dots">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
