import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Thumbs, Autoplay } from "swiper/modules";
import axios from "axios";
import ImageSliderLoading from "../loading/ImageSliderLoading";

const ImageSlider = ({ selectedProduct }) => {
  const thumbsSwiper = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.realIndex);
  };

  useEffect(() => {
    const fetchImages = async () => {
      if (selectedProduct) {
        try {
          const imagesResponse = await axios.get(
            `https://admin.demiidesign.com/api/Get/Image/Product/${selectedProduct.Product_Name}`
          );
          setImages(
            Array.isArray(imagesResponse.data)
              ? imagesResponse.data
              : ["defaultImage.jpg"]
          );
        } catch (error) {
          console.error("Error fetching images:", error);
          setImages(["defaultImage.jpg"]); // Fallback image
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchImages();
  }, [selectedProduct]);

  if (isLoading) {
    return <ImageSliderLoading />; // Display loading state
  }

  return (
    <div className="md:w-[88%] w-full">
      <div className="">
        <div className="md:h-[28rem] h-[22rem] relative">
          <Swiper
            loop={true}
            autoplay={true}
            onSlideChange={handleSlideChange}
            spaceBetween={10}
            thumbs={{ swiper: thumbsSwiper.current }}
            modules={[FreeMode, Thumbs, Autoplay]}
            className="mySwiper2 w-full h-full bg-slate-200"
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  className="w-full h-full object-cover"
                  src={image}
                  alt="placeholder.jpg"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <Swiper
        onSwiper={(swiper) => {
          thumbsSwiper.current = swiper;
        }}
        spaceBetween={10}
        loop={true}
        slidesPerView={4}
        watchSlidesProgress={true}
        modules={[FreeMode, Thumbs]}
        className="mySwiper mt-4"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img
              className={`${
                index === activeIndex ? "opacity-100" : "opacity-70"
              } cursor-pointer w-2/3 transition-all duration-100 h-20 rounded-lg object-cover m-4 md:m-0`}
              src={image}
              alt={`Thumbnail ${index + 1}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ImageSlider;
