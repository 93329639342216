import React from "react";
import "../../../src/ImageLoading.css"; // Import the CSS file for styling

function ImageLoading() {
  return (
    <div className="loading-card">
      <div className="loading-image skeleton"></div>
      <div className="loading-icon skeleton"></div>
      <div className="loading-details">
        <div className="loading-text skeleton"></div>
        <div className="loading-price skeleton"></div>
        <div className="loading-size skeleton"></div>
      </div>
    </div>
  );
}

export default ImageLoading;
