// redux/archiveReducer.js

const initialState = {
  archives: [],
  selectedYear: null,
  loading: false,
  error: null,
};

const archiveReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ARCHIVES":
      return {
        ...state,
        archives: action.payload,
        loading: false,
        error: null,
      };
    case "SET_SELECTED_YEAR":
      return {
        ...state,
        selectedYear: action.payload,
      };
    case "FETCH_ARCHIVES_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_ARCHIVES_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default archiveReducer;
