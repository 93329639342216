import React from 'react'


const OrderModal = ({ children }) => {
  return (
    <div className='fixed inset-0 backdrop-blur-sm scrollbar-hide z-50 flex justify-center items-center bg-black bg-opacity-30'>
      <div className='bg-white shadow-lg max-h-full overflow-y-auto scrollbar-hide'>
        <div className=''>{children}</div>
      </div>
    </div>
  )
}

export default OrderModal