import logo from "../assets/logo/Demii_Logo.jpg";
import c2 from "../assets/statics/demi_pic.jpg";
import StoryText from "../data/Story";

const Story = () => {
  return (
    <div className="flex flex-col justify-center items-center p-7 md:p-16">
      <h1 className="text-slate-500 text-3xl font-bold">
        About Demii's Design
      </h1>
      <div className="p-10 flex flex-col justify-center items-center space-y-5">
        <img src={logo} alt="" className="w-20 md:w-36 rounded-full" />
        <StoryText index={0} position={"center"} />
        <StoryText index={1} position={"center"} />
        <h1 className="text-slate-500 text-3xl font-semibold">Mission</h1>
        <StoryText index={3} position={"center"} />
        <h1 className="text-slate-500 text-3xl font-semibold">Vision</h1>
        <StoryText index={4} position={"center"} />
      </div>
      <div className="flex flex-col items-center md:flex-row justify-between p-5 bg-white w-full space-y-8">
        <img src={c2} alt="" className="w-80 md:w-2/5 h-full" />
        <div className="pl-9 space-y-4 flex flex-col justify-center md:items-start">
          <h1 className="text-slate-500 text-3xl font-bold text-center ">
            Behind Demii Design
          </h1>
          <StoryText index={2} position={"start"} responsive={'center'}/>
        </div>
      </div>
    </div>
  );
};

export default Story;
