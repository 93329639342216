import {
  ADD_ORDER,
  REMOVE_ORDER,
  UPDATE_ORDER_SIZE,
  UPDATE_ORDER_AMOUNT,
} from "../actions/orderAction";

const initialState = [
  {
    amount: 1,
    selectedSize: "",
  },
];

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ORDER:
      return [...state, action.payload];
    case REMOVE_ORDER:
      return state.filter((_, index) => index !== action.payload);
    case UPDATE_ORDER_SIZE:
      return state.map((order, index) =>
        index === action.payload.index
          ? { ...order, selectedSize: action.payload.size }
          : order
      );
    case UPDATE_ORDER_AMOUNT:
      return state.map((order, index) =>
        index === action.payload.index
          ? { ...order, amount: action.payload.amount }
          : order
      );
    default:
      return state;
  }
};

export default orderReducer;
